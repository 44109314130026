import {
	getEventThunk,
	getEventInBackgroundThunk,
	getEventFromCacheThunk,
	liveAndUpcomingsThunk,
	resultsThunk,
	addResultThunk,
	lastResultsThunk,
	getSeasonResultsThunk
} from "./thunks";

import {
	removeFromLiveAndUpcomingsAction,
	addLiveAndUpcomingsAction,
	updateFromLiveAndUpcomingsAction,
	removeFromLiveAndUpcomingsBySeasonAction,
	setCurrentEventIdAction,
	setLastResultsAction,
	addLastResultAction,
	setCurrentGameTypeAction,
	setGameCountDownAction,
	setCurrentGameBonusBetDisabledAction,
	setMarketsCollapsibleActiveKeysAction,
	updateEventAction,
	clearSeasonResultAction,
	setResultsFiltersAction,
	setSeasonResultsFiltersAction,
	clearSeasonResultsFiltersAction
} from "./actions";

import { isSeasonGame } from "utils/common";
import runMarketUtilsFunction from "utils/markets/run";
import { GAME_STATUSES, GAME_EVENT_TYPE } from "constants/game.constants";

export const addLiveAndUpcomingsCases = (builder) => {
	builder.addCase(liveAndUpcomingsThunk.pending, (state) => {
		state.liveAndUpcomings.loading = true;
	});

	builder.addCase(liveAndUpcomingsThunk.fulfilled, (state, { payload: data }) => {
		state.liveAndUpcomings.loading = false;

		if (data.find((e) => e.gameType === state.currentGameType)) {
			let upcommingData = [...data];
			let nextUpcommingData = [];

			if (isSeasonGame(state.currentGameType) && data) {
				const sortedData = [...data].sort((next, prev) => (next.id > prev.id ? 1 : -1));

				upcommingData = sortedData[0] ? sortedData[0].events || [] : [];
				nextUpcommingData = sortedData[1] ? sortedData[1].events || [] : [];
			}

			const liveAndUpcomings = upcommingData.concat(nextUpcommingData);

			const currentMatch = liveAndUpcomings.find((d) => d.status !== GAME_STATUSES.STARTED && d.status !== GAME_STATUSES.FINISHED) || liveAndUpcomings[0];
			state.currentEventId = currentMatch.id;
			state.liveAndUpcomings.data = liveAndUpcomings;
		}
	});

	builder.addCase(liveAndUpcomingsThunk.rejected, (state) => {
		state.liveAndUpcomings.loading = false;
	});

	builder.addCase(removeFromLiveAndUpcomingsAction, (state, { payload }) => {
		const removeLiveAndUpcomingsStatuses = [GAME_STATUSES.STARTED, GAME_STATUSES.CLOSE_FOR_BETTING];
		const { filtered, nextGame } = state.liveAndUpcomings.data.reduce((acc, lau) => {
			if (payload.id === lau.id) { return acc; }
			acc.filtered.push(lau);
			if (!acc.nextGame && !removeLiveAndUpcomingsStatuses.includes(lau.status)) {
				acc.nextGame = lau;
			}
			return acc;
		}, { filtered: [], nextGame: null })
		const removeLiveAndUpcomingsMatchesData = { ...state.matches.data };
		delete removeLiveAndUpcomingsMatchesData[payload.id];

		state.liveAndUpcomings.data = filtered;
		state.matches.data = removeLiveAndUpcomingsMatchesData;

		if (
			nextGame?.id && (payload.id === state.currentEventId ||
				filtered.some(({ id, status }) => removeLiveAndUpcomingsStatuses.includes(status) && id === state.currentEventId))
		) {
			state.currentEventId = nextGame.id;
		}
	});

	builder.addCase(addLiveAndUpcomingsAction, (state, { payload }) => {
		let needAdd = false;
		let addedLiveAndUpcomings = [];

		if (isSeasonGame(payload.event.gameType)) {
			if (payload.event.type === GAME_EVENT_TYPE.LEAGUE && Array.isArray(payload.event.events)) {
				needAdd = true;
				addedLiveAndUpcomings = [...state.liveAndUpcomings.data];
				payload.event.events.forEach(subEvent => {
					const index = addedLiveAndUpcomings.findIndex(e => e.id === subEvent.id);
					if (index > -1) {
						addedLiveAndUpcomings[index] = subEvent;
					} else {
						addedLiveAndUpcomings = addedLiveAndUpcomings.concat(subEvent);
					}
				})
			}
		} else {
			needAdd = true;
			addedLiveAndUpcomings = [...state.liveAndUpcomings.data];
			const index = addedLiveAndUpcomings.findIndex(e => e.id === payload.event.id);
			if (index > -1) {
				addedLiveAndUpcomings[index] = payload.event;
			} else {
				addedLiveAndUpcomings = addedLiveAndUpcomings.concat(payload.event);
			}
		}
		if (!needAdd) { return }

		state.liveAndUpcomings.data = addedLiveAndUpcomings;
	})

	builder.addCase(updateFromLiveAndUpcomingsAction, (state, { payload }) => {
		const updated = state.liveAndUpcomings.data.map(d => payload.event.id === d.id ? payload.event : d);

		state.liveAndUpcomings.data = updated;
	});

	builder.addCase(removeFromLiveAndUpcomingsBySeasonAction, (state, { payload }) => {
		const removeLiveAndUpcomingsBySeasonStatuses = [GAME_STATUSES.STARTED, GAME_STATUSES.FINISHED];
		const { updatedBySeason, currentIdAfterRemove = updatedBySeason[0] } = state.liveAndUpcomings.data.reduce((acc, lau) => {
			if (payload.id === lau.seasonId) { return acc; }
			acc.updatedBySeason.push(lau);
			if (!acc.currentIdAfterRemove && !removeLiveAndUpcomingsBySeasonStatuses.includes(lau.status)) {
				acc.currentIdAfterRemove = lau;
			}
			return acc;
		}, { updatedBySeason: [], currentIdAfterRemove: undefined });

		state.liveAndUpcomings.data = updatedBySeason;
		state.currentEventId = currentIdAfterRemove ? currentIdAfterRemove.id : state.currentEventId;
	});
}

export const addEventCasees = (builder) => {
	builder.addCase(setCurrentEventIdAction, (state, { payload }) => {
		state.currentEventId = payload.id;
	});

	builder.addCase(getEventThunk.pending, (state) => {
		state.matches.loading = true;
	});

	builder.addCase(getEventThunk.fulfilled, (state, { payload: matchData }) => {
		state.matches.loading = false;

		const setEventMatchesData = { ...state.matches.data };
		setEventMatchesData[matchData.id] = matchData;

		state.matches.data = setEventMatchesData;
	});

	builder.addCase(getEventThunk.rejected, (state) => {
		state.matches.loading = false;
	});

	builder.addCase(getEventInBackgroundThunk.fulfilled, (state, { payload: matchData }) => {
		state.matches.loading = false;

		const setEventMatchesData = { ...state.matches.data };
		setEventMatchesData[matchData.id] = matchData;

		state.matches.data = setEventMatchesData;
	});

	builder.addCase(getEventInBackgroundThunk.rejected, (state) => {
		state.matches.loading = false;
	});

	builder.addCase(getEventFromCacheThunk.pending, (state) => {
		state.matches.loading = true;
	});

	builder.addCase(getEventFromCacheThunk.fulfilled, (state) => {
		state.matches.loading = false;
	});

	builder.addCase(updateEventAction, (state, { payload }) => {
		let needUpdate = false;
		let updateEventMatchesData = null;

		if (state.matches.data[payload.id]) {
			needUpdate = true;
			updateEventMatchesData = { ...state.matches.data };
			updateEventMatchesData[payload.id] = {
				...updateEventMatchesData[payload.id],
				event: {
					...updateEventMatchesData[payload.id].event,
					status: payload.data.status,
					gameData: payload.data.gameData,
					markets: (
						payload.data.markets && [GAME_EVENT_TYPE.EVENT, GAME_EVENT_TYPE.WEEK].includes(payload.data.type)
							? payload.data.markets
							: updateEventMatchesData[payload.id].event.markets
					),
					marketsCount: (
						payload.data.marketsCount && [GAME_EVENT_TYPE.EVENT, GAME_EVENT_TYPE.WEEK].includes(payload.data.type)
							? payload.data.marketsCount
							: updateEventMatchesData[payload.id].event.marketsCount
					)
				},
				markets: (
					payload.data.markets && [GAME_EVENT_TYPE.EVENT, GAME_EVENT_TYPE.WEEK].includes(payload.data.type) && isSeasonGame(payload.data.gameType)
						? runMarketUtilsFunction(
							"makeAllMarkets",
							[
								payload.data.markets,
								payload.data.gameData,
								payload.rtps,
								state.currentGameType
							],
							state.currentGameType
						)
						: updateEventMatchesData[payload.id].markets
				)
			};
		}

		if (
			payload.data.type === GAME_EVENT_TYPE.EVENT &&
			state.matches.data[payload.data.parentEventId] &&
			state.matches.data[payload.data.parentEventId].event &&
			Array.isArray(state.matches.data[payload.data.parentEventId].event.events)
		) {
			needUpdate = true;
			updateEventMatchesData = updateEventMatchesData || { ...state.matches.data };
			const updatedSubEvents = updateEventMatchesData[payload.data.parentEventId].event.events.map(e => {
				if (e.id !== payload.id) { return e; }
				return (
					{
						...payload.data,
						markets: payload.data.markets && isSeasonGame(payload.data.gameType) ? payload.data.markets : e.markets,
						marketsCount: payload.data.markets && isSeasonGame(payload.data.gameType) ? payload.data.marketsCount : e.marketsCount,
					}
				);
			});
			updateEventMatchesData[payload.data.parentEventId] = {
				...updateEventMatchesData[payload.data.parentEventId],
				event: {
					...updateEventMatchesData[payload.data.parentEventId].event,
					events: updatedSubEvents
				}
			};
		}
		if (!needUpdate) { return; }

		state.matches.data = updateEventMatchesData;
	});
}

export const addResultsCases = (builder) => {
	builder.addCase(resultsThunk.pending, (state) => {
		state.results.loading = true;
	});

	builder.addCase(resultsThunk.fulfilled, (state, { payload }) => {
		state.results.loading = false;
		if (state.currentGameType !== payload.gameType) { return; }

		state.results.data = payload.data.item2;
		state.results.total = payload.data.item1;
	});

	builder.addCase(resultsThunk.rejected, (state) => {
		state.results.loading = false;
	});

	builder.addCase(addResultThunk.pending, (state) => {
		state.results.loading = true;
	});

	builder.addCase(addResultThunk.fulfilled, (state, { payload: data }) => {
		state.results.loading = false;
		state.results.data = state.results.data.concat(data.item2);
		state.results.total = data.item1;
	});

	builder.addCase(addResultThunk.rejected, (state) => {
		state.results.loading = false;
	});

	builder.addCase(setResultsFiltersAction, (state, { payload }) => {
		state.results.filters = { ...state.results.filters, ...payload.filters };
	});

}

export const addLastResultsCases = (builder) => {
	builder.addCase(lastResultsThunk.pending, (state) => {
		state.lastResults.loading = true;
	});

	builder.addCase(lastResultsThunk.fulfilled, (state, { payload: data }) => {
		state.lastResults.loading = false;
		state.lastResults.data = data;
	});

	builder.addCase(lastResultsThunk.rejected, (state) => {
		state.lastResults.loading = false;
	});

	builder.addCase(setLastResultsAction, (state, { payload: { data } }) => {
		state.lastResults.data = data;
	});

	builder.addCase(addLastResultAction, (state, { payload: { result } }) => {
		state.lastResults.data = [result, ...state.lastResults.data].slice(0, 5);
	});
}

export const addSeasonResultsCases = (builder) => {
	builder.addCase(getSeasonResultsThunk.pending, (state) => {
		state.seasonResult.loading = true;
	});

	builder.addCase(getSeasonResultsThunk.fulfilled, (state, { payload }) => {
		state.seasonResult.loading = false;
		state.seasonResult.data = payload.data;
		state.seasonResult.seasonId = payload.id;
	});

	builder.addCase(getSeasonResultsThunk.rejected, (state) => {
		state.seasonResult.loading = false;
	});

	builder.addCase(clearSeasonResultAction, (state) => {
		state.seasonResult.data = {};
		state.seasonResult.seasonId = null;
	});

	builder.addCase(setSeasonResultsFiltersAction, (state, { payload }) => {
		state.seasonResult.filters = { ...state.seasonResult.filters, ...payload.filters };
	});

	builder.addCase(clearSeasonResultsFiltersAction, (state) => {
		state.seasonResult.filters = { ...state.seasonResult.filters, id: "" };
	});
}

export const addGameCases = (builder) => {
	builder.addCase(setCurrentGameTypeAction, (state, { payload }) => {
		state.currentGameType = payload.type;
		state.matches.data = {};
		state.liveAndUpcomings.data = [];
		state.currentEventId = null;
		state.lastResults.data = [];
		state.seasonResult.seasonId = null;
	});

	builder.addCase(setGameCountDownAction, (state, { payload }) => {
		state.gameCountDown[payload.data.gameId] = payload.data;
	})

	builder.addCase(setCurrentGameBonusBetDisabledAction, (state, { payload }) => {
		state.isBonusBetDisabled = payload.isDisabled;
	})
}

export const addActiveMarketsKeysCases = (builder) => {
	builder.addCase(setMarketsCollapsibleActiveKeysAction, (state, { payload }) => {
		state.marketsActiveKeys[payload.tab] = payload.keys;
	});
}