import { 
	getHistoryThunk,
	addHistoryThunk
} from "./thunks";

import { 
	updateHistoryBetSlipBetAction,
	updateHistoryBetSlipAction,
	setHistoryOpenedBetSlipIdAction,
	cancelBetslipHistoryAction,
	setHistoryAction,
	setHistoryFiltersAction,
	resetHistoryAction
} from "./actions";

import { getDefaultDate } from "utils/dateTime";

import { BET_STATE } from "constants/betslip.constants";

export const addHistoryCases = (builder) => {
	builder.addCase(getHistoryThunk.pending, (state) => {
		state.loading = true;
	});

	builder.addCase(getHistoryThunk.fulfilled, (state, { payload: data }) => {
		state.loading = false;
		state.total = data.item1;
		state.data = data.item2;
		state.loaded = true;
	});

	builder.addCase(getHistoryThunk.rejected, (state) => {
		state.loading = false;
	});

	builder.addCase(addHistoryThunk.fulfilled, (state, { payload: data }) => {
		state.total = data.item1;
		state.data = state.data.concat(data.item2);
		state.loaded = true;
	});

	builder.addCase(updateHistoryBetSlipBetAction, (state, { payload }) => {
		state.data = state.data.map((d) => ({ ...d, bets: d.bets.map((bet) => (bet.id === payload.bet.id ? payload.bet : bet)) }));
	});

	builder.addCase(updateHistoryBetSlipAction, (state, { payload }) => {
		state.data = state.data.map((d) => (d.id === payload.bet.id ? { ...d, ...payload.bet, bets: d.bets } : d));
	});

	builder.addCase(cancelBetslipHistoryAction, (state, { payload }) => {
		state.data = state.data.map((d) => (d.id !== payload.bet.id ? d : { ...d, state: BET_STATE.CANCELLED, bets: d.bets.map((det) => ({ ...det, state: BET_STATE.CANCELLED, allowCancel: false })) }))
	});

	builder.addCase(setHistoryAction, (state, { payload }) => {
		state.total = payload.data.item1;
		state.data = payload.data.item2;
		state.loaded = true;
	});

	builder.addCase(setHistoryFiltersAction, (state, { payload }) => {
		state.filters = { ...state.filters, ...payload.filters };
		state.filtersReset = false;
	});

	builder.addCase(resetHistoryAction, (state) => {
		state.data = [];
		state.total = 0;
		state.loaded = false;

		state.filters = {
			page: 1,
			limit: 20,
			...getDefaultDate()
		},
		state.filtersReset = true;
	});

	builder.addCase(setHistoryOpenedBetSlipIdAction, (state, { payload }) => {
		state.openedBetSlipId = payload.id;
	});
};