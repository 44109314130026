import { useEffect, Fragment, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { liveAndUpcomingsThunk, getEventFromCacheThunk } from "store/slices/game/thunks";
import {
	removeFromLiveAndUpcomingsAction,
	removeFromLiveAndUpcomingsBySeasonAction,
	addLiveAndUpcomingsAction,
	updateFromLiveAndUpcomingsAction,
	addLastResultAction,
	setGameCountDownAction,
	setCurrentGameBonusBetDisabledAction,
	updateEventAction
} from "store/slices/game/actions";
import {
	setLiveInfoEventAction,
	updateLiveInfoEventAction,
	setEventPositionsAction,
	setCurrentTimeAction
} from "store/slices/common/actions";
import { updateHistoryBetSlipBetAction, updateHistoryBetSlipAction } from "store/slices/betHistory/actions";
import {
	removePendingAction,
	updatePendingAction,
	updatePendingBetAction,
	updateMatchBetsAction,
	addBetslipResultAction,
	setBetslipAction
} from "store/slices/betslip/actions";
import { updateSeasonMarketsAction, clearSeasonMarketsAction } from "store/slices/season/actions";
import { setBalanceAction, setLogoIdAction } from "store/slices/auth/actions";
import { setBonusThunk } from "store/slices/auth/thunks";
import { setKenoStatisticsAction, updateSeasonStructureAction, updateSeasonStatisticsAction } from "store/slices/statistics/actions";
import { getLastBetsThunk } from "store/slices/keno/thunks";
import {
	selectSessionId,
	selectSessionLoaded,
	selectSessionFailed,
	selectSessionGames,
	selectSessionBonuses,
	selectSessionProjectId,
	selectPlayer,
	selectIsSplitStakeEnabled,
	selectAnalyticalTools
} from "store/slices/auth/selectors";
import { selectSeasonCurrentEventId, selectSpecialMarketsSeasonId } from "store/slices/season/selectors";
import { selectUseBonus } from "store/slices/bonus/selectors";
import { selectCurrentGameType, selectCurrentEventId, selectLiveAndUpcomingsData, selectMatchesData } from "store/slices/game/selectors";
import { selectLiveInfoEvent } from "store/slices/common/selectors";
import { selectBetslipMode, selectBets, selectBetslipStake, selectBetslipStakeMode } from "store/slices/betslip/selectors";

import { CLIENT_API } from "constants/integration.constants";
import { GAME_STATUSES, GAME_TYPE, GAME_EVENT_TYPE } from "constants/game.constants";
import { BET_STATE, BETSLIP_STAKE_MODES, BETSLIP_MODES } from "constants/betslip.constants";
import { ANALYTICAL_TOOL_TYPE, CONNECTION_STATE, TASK_SCHEDULER_TIME_PERIODS } from "constants/common.constants";

import SignalRUtils from "utils/signalR";
import LocalStorageUtils from "utils/localStorage";
import { isSeasonGame, isCupGame } from "utils/common";
import { refreshToken } from "utils/auth";
import { binaryToFlags } from "utils/binaryCalculations";
import { initializeGA, sendGAPageView } from "utils/ga";
import { initializeHotjar } from "utils/hotjar";
import { initializeWVO } from "utils/vwo";
import { initializeYandexMetrica } from "utils/yandexMetrica";
import { sendPostMessageToParent } from "utils/iframe";
import { generateCssFromProperties } from "utils/css";

import useGlobalVariables from "hooks/useGlobalVariables";
import useAppDispatch from "hooks/store/useAppDispatch";
import useAppSelector from "hooks/store/useAppSelector";
import useTaskScheduler from "hooks/useTaskScheduler";
import useEvent from "hooks/useEvent";
import useGetEvent from "hooks/game/useGetEvent";
import useExitFromSession from "hooks/auth/useExitFromSession";
import { REFRESH_TOKEN_UPDATE } from "constants/date.constants";

let refreshTokenTimer = null;

/* Main functional Component - Initial functionality for all pages */
const Main = () => {
	const globalVariables = useGlobalVariables();

	const games = useAppSelector(selectSessionGames);
	const bonuses = useAppSelector(selectSessionBonuses);
	const projectId = useAppSelector(selectSessionProjectId);
	const isSplitStakeEnabled = useAppSelector(selectIsSplitStakeEnabled);
	const analyticalTools = useAppSelector(selectAnalyticalTools);
	const mode = useAppSelector(selectBetslipMode);
	const bets = useAppSelector(selectBets);
	const stake = useAppSelector(selectBetslipStake);
	const stakeMode = useAppSelector(selectBetslipStakeMode);
	const sessionId = useAppSelector(selectSessionId);
	const sessionLoaded = useAppSelector(selectSessionLoaded);
	const sessionFailed = useAppSelector(selectSessionFailed);
	const seasonCurrentEventId = useAppSelector(selectSeasonCurrentEventId);
	const player = useAppSelector(selectPlayer);
	const useBonus = useAppSelector(selectUseBonus);
	const currentGameType = useAppSelector(selectCurrentGameType);
	const current = useAppSelector(selectCurrentEventId);
	const liveAndUpcomings = useAppSelector(selectLiveAndUpcomingsData);
	const liveInfoEvent = useAppSelector(selectLiveInfoEvent);
	const matches = useAppSelector(selectMatchesData);
	const specialMarketsSeasonId = useAppSelector(selectSpecialMarketsSeasonId);

	const { getEvent, getEventInBackground } = useGetEvent();
	const exitFromSession = useExitFromSession();

	const dispatch = useAppDispatch();

	const variableRef = useRef();
	variableRef.current = globalVariables;
	const currentGameTypeRef = useRef(currentGameType);

	/*
		get active event and next upcoming event from live and upcomings events array
	*/
	const { activeEventId, upcomingEventId } = useMemo(() => {
		let _activeEventId = null;
		let _upcomingEventId = null;

		const statusesForActive = [GAME_STATUSES.STARTED, GAME_STATUSES.PREAMBLE_STARTED, GAME_STATUSES.CLOSE_FOR_BETTING];
		const statusesForUpcoming = [GAME_STATUSES.PREAMBLE_STARTED, GAME_STATUSES.NEW];

		liveAndUpcomings.some((lau) => {
			if (_activeEventId === null && statusesForActive.includes(lau.status)) {
				_activeEventId = lau.id;
			}

			if (_upcomingEventId === null && statusesForUpcoming.includes(lau.status)) {
				_upcomingEventId = lau.id;
			}

			return _activeEventId !== null && _upcomingEventId !== null;
		});

		return { activeEventId: _activeEventId, upcomingEventId: _upcomingEventId };
	}, [liveAndUpcomings]);

	/** Function to get current game rtps
	 * @function
	 * @returns {array}
	 * @memberOf MarketsTabs
	 */
	const getCurrentGameRtps = () => {
		let rtps = [];
		let game = games.find((g) => g.type === currentGameType);
		if (game) {
			rtps = game.rtPs;
		}
		return rtps;
	};

	/** Sending event/week status to the RGS, when getting upgrade from SignalR */
	const sendEventStatusToRGS = (event) => {
		if ((event.status === GAME_STATUSES.FINISHED || event.status === GAME_STATUSES.STARTED) && event.type !== GAME_EVENT_TYPE.LEAGUE) {
			if (isSeasonGame(event.gameType)) {
				if (event.type === GAME_EVENT_TYPE.WEEK) {
					sendPostMessageToParent({ type: CLIENT_API.EVENT, state: event.status === GAME_STATUSES.STARTED ? "start" : "finish" });
				}
			} else {
				sendPostMessageToParent({ type: CLIENT_API.EVENT, state: event.status === GAME_STATUSES.STARTED ? "start" : "finish" });
			}
		}
	};

	const allowConectEventUpdates = (connectionURL, necessaryConnectionURL) => {
		try {
			if (!Boolean(necessaryConnectionURL)) {
				return true
			}
			const url = new URL(connectionURL);
			if (necessaryConnectionURL === (url.origin + url.pathname)) {
				return true
			}
		} catch (error) {
			console.log(error);
		}

		return false
	}

	/** Function to subscribe and handle signalR events
	 * @function
	 * @description checks to allow only numeric characters
	 * @memberOf Container
	 */
	const handleSignalREvents = useEvent((connectionArg) => {
		SignalRUtils.getConnections().forEach((con, index) => {
			if (con !== connectionArg) {
				return
			}
			const connection = con.getConnection();
			if ((index === 0 || index === 2) && connection.state === "Connected") {
				games.forEach((game) => {
					connection.invoke("Subscribe", `Events_${game.type}_${game.id}`);
				});
			} else if (index === 1 && connection.state === "Connected") {
				setInterval(() => {
					if (connection.state === "Connected") {
						connection.invoke("UpdateSession", sessionId, 1, player.userId);
					}
				}, 60000);
			}

			connection.off("Events");
			connection.off("EventPositions");
			connection.off("Logout");
			connection.off("EventCountDown");
			connection.off("BetSlip");
			connection.off("BetSlipBet");
			connection.off("WonPopup");
			connection.off("Balance");
			connection.off("Bonus");
			connection.off("KenoStatistics");
			connection.off("TeamStandings");
			connection.off("Logout");

			const onEvent = (data) => {
				try {
					const d = JSON.parse(data);
				console.vsLogger("Events", d);
				if (d.gameType === currentGameTypeRef.current) {
					sendEventStatusToRGS(d);
					if (d.status === GAME_STATUSES.FINISHED) {
						if (!isSeasonGame(d.gameType)) {
							dispatch(removeFromLiveAndUpcomingsAction(d.id));
							dispatch(addLastResultAction(d));
						} else if (d.type === GAME_EVENT_TYPE.LEAGUE) {
							dispatch(removeFromLiveAndUpcomingsBySeasonAction(d.id));
							dispatch(clearSeasonMarketsAction());
						} else {
							dispatch(updateFromLiveAndUpcomingsAction(d));
						}
					} else if (
						[
							GAME_STATUSES.STARTED,
							GAME_STATUSES.CLOSE_FOR_BETTING,
							GAME_STATUSES.PREAMBLE_STARTED,
						].includes(d.status)
					) {
						if (!isSeasonGame(d.gameType) || d.type === GAME_EVENT_TYPE.WEEK) {
							dispatch(updateFromLiveAndUpcomingsAction(d));
						}
						dispatch(updateLiveInfoEventAction(d));
					} else if (d.status === GAME_STATUSES.NEW) {
						if (!isSeasonGame(d.gameType)) {
							dispatch(addLiveAndUpcomingsAction(d));
						} else {
							if (d.type === GAME_EVENT_TYPE.LEAGUE) {
								dispatch(addLiveAndUpcomingsAction(d));
							}
						}
					}

					/** If season markets are updated */
					if (isSeasonGame(d.gameType) && d.markets) {
						if (d.id === specialMarketsSeasonId) {
							const currentGame = games.find((g) => g.type === currentGameType);
							const rtps = currentGame?.rtPs ?? [];
							const index = liveAndUpcomings.findIndex((lau) => lau.seasonId === d.id);

							dispatch(updateSeasonMarketsAction({ index, season: d, rtps, gameType: currentGameType }));
						}
					}
				}
				dispatch(updateEventAction({ id: d.id, data: d, rtps: getCurrentGameRtps() }));
				dispatch(updateMatchBetsAction(d));
				if (isCupGame(d.gameType) && d.gameType === currentGameType) {
					dispatch(updateSeasonStructureAction(d));
				}
				} catch(err) {
					console.log("AAA", err)
				}
			}

			const onEventCountDown = (data) => {
				const d = JSON.parse(data);
				console.vsLogger("EventCountDown", d);
				dispatch(setGameCountDownAction(d));
			}

			const onEventPositions = (data) => {
				const d = JSON.parse(data);
				console.vsLogger("EventPositions", d);
				dispatch(setEventPositionsAction(d));
			}

			const onTeamStandings = (data) => {
				const d = JSON.parse(data);
				console.vsLogger("TeamStandings", d);
				if (d.gameType === currentGameType) {
					dispatch(updateSeasonStatisticsAction(d));
				}
			}

			const onKenoStatistics = (data) => {
				if (currentGameTypeRef.current === GAME_TYPE.KENO) {
					const d = JSON.parse(data);
					dispatch(setKenoStatisticsAction(d));
					dispatch(getLastBetsThunk());
				}
			}

			const onWonPopup = (data) => {
				const d = JSON.parse(data);
				console.vsLogger("WonPopup", d);
				dispatch(addBetslipResultAction(d.bets));
			}
			if (allowConectEventUpdates(con.connectionURL, import.meta.env.SYSTEM_SIGNALR_URL_JOBS)) {
				connection.on("Events", onEvent);
				connection.on("EventCountDown", onEventCountDown);
				connection.on("EventPositions", onEventPositions);
				connection.on("TeamStandings", onTeamStandings);
				connection.on("KenoStatistics", onKenoStatistics);
				connection.on("WonPopup", onWonPopup);
			}

			connection.on("BetSlip", (data) => {
				const d = JSON.parse(data);
				console.vsLogger("BetSlip", d);
				dispatch(updateHistoryBetSlipAction(d));
				if (d.state !== BET_STATE.PENDING) {
					dispatch(removePendingAction(d));
				} else {
					dispatch(updatePendingAction(d));
				}
			});

			connection.on("BetSlipBet", (data) => {
				const d = JSON.parse(data);
				console.vsLogger("BetSlipBet", d);
				dispatch(updateHistoryBetSlipBetAction(d));
				dispatch(updatePendingBetAction(d));
			});

			connection.on("Bonus", (data) => {
				const bonus = JSON.parse(data);
				console.vsLogger("Bonus", bonus);
				if (bonus === null) {
					return;
				}
				if (moment.utc(bonus.endDate) <= moment.utc(Date.now())) {
					bonus.roundCount = 0;
					bonus.amount = 0;
				}
				dispatch(setBonusThunk({ bonus, sessionId, options: variableRef.current }));
			});

			connection.on("Balance", (data) => {
				const { bonus, balance } = JSON.parse(data);
				dispatch(setBalanceAction(Number(balance)));
				if (bonus === null) {
					return;
				}
				if (moment.utc(bonus.endDate) <= moment.utc(Date.now())) {
					bonus.roundCount = 0;
					bonus.amount = 0;
				}
				dispatch(setBonusThunk({ bonus, sessionId, options: variableRef.current }));
			});

			connection.on("Logout", () => {
				exitFromSession();
			});

		});
	});

	/** Keep current time */
	useEffect(() => {
		dispatch(setCurrentTimeAction());
		setInterval(() => {
			dispatch(setCurrentTimeAction());
		}, 1000);
	}, []);

	/** Load Live and Upcomings Matches */
	useEffect(() => {
		if (currentGameType !== null) {
			dispatch(liveAndUpcomingsThunk(currentGameType));
		}
		currentGameTypeRef.current = currentGameType;
		const containerElem = document.getElementsByClassName("vs--container")[0];
		containerElem && containerElem.setAttribute("data-game", currentGameType);
	}, [currentGameType]);

	/** Enable/Disable bonus bet for current game when useBonus is activated by user */
	useEffect(() => {
		if (!useBonus) {
			dispatch(setCurrentGameBonusBetDisabledAction(useBonus));
		} else {
			const availableGamesTypes = games.map((game) => game.type);
			const bonusGamesTypes = binaryToFlags(availableGamesTypes, bonuses[0].gameType);

			dispatch(setCurrentGameBonusBetDisabledAction(!bonusGamesTypes.includes(currentGameType)));
		}
	}, [currentGameType, useBonus]);

	/** Always get next upcoming event, to have its markets */
	useEffect(() => {
		if (upcomingEventId) {
			if (!matches?.[upcomingEventId]) {
				getEventInBackground(upcomingEventId, false, variableRef.current);
			}
		}
	}, [upcomingEventId]);

	/** Always get current active event, to keep liveInfo updated */
	useEffect(() => {
		if (activeEventId) {
			if (!matches?.[activeEventId]) {
				getEventInBackground(activeEventId, true, variableRef.current);
			} else {
				if (liveInfoEvent && liveInfoEvent.status !== GAME_STATUSES.FINISHED) {
					dispatch(setLiveInfoEventAction(matches?.[activeEventId]?.event ?? null));
				}
			}
		}
	}, [activeEventId]);

	/** Load current match data and markets */
	useEffect(() => {
		if (current) {
			if (!matches?.[current]) {
				getEvent(current, variableRef.current);
			} else {
				dispatch(getEventFromCacheThunk());
			}
		}
	}, [current]);

	/** Load current event data and markets for season */
	useEffect(() => {
		if (isSeasonGame(currentGameType) && seasonCurrentEventId) {
			if (!matches?.[seasonCurrentEventId]) {
				getEvent(seasonCurrentEventId, variableRef.current);
			} else {
				dispatch(getEventFromCacheThunk());
			}
		}
	}, [seasonCurrentEventId, currentGameType]);

	/** If less then 2 minute left to refresh token expiration, then refresh it */
	useEffect(() => {
		clearTimeout(refreshTokenTimer);
		if (player.refreshToken) {
			refreshTokenTimer = setTimeout(() => {
				refreshToken(player.refreshToken);
			}, REFRESH_TOKEN_UPDATE * 1000);
		}
	}, [player.refreshToken]);

	/** Subscribe to signalR when session loaded */
	useEffect(() => {
		if (!sessionLoaded || sessionFailed) {
			return;
		}
		const timeoutId = setTimeout(() => {
			console.log("CONNECTED");
			SignalRUtils.buildConnections(handleSignalREvents);
		}, 1000);
		return () => {
			SignalRUtils.removeConnections()
			clearTimeout(timeoutId)
			console.log("DISCONNECTED")
		}

	}, [sessionLoaded, player.wsToken]);

	useEffect(() => {
		if (!sessionLoaded || sessionFailed) {
			return;
		}
		const betslip = LocalStorageUtils.get("vs__" + projectId);
		if (betslip) {
			dispatch(setBetslipAction({
				...betslip,
				stakeMode: isSplitStakeEnabled ? betslip.stakeMode : BETSLIP_STAKE_MODES.PER_BET
			}));
		}
	}, [sessionLoaded]);

	/** keep redux sync with localstorage, for the data which need to be saved in browser */
	useEffect(() => {
		if (projectId) {
			LocalStorageUtils.set("vs__" + projectId, {
				bets: bets,
				stake: stake,
				stakeMode: stakeMode,
				mode: mode
			});
		}
	}, [bets, stake, stakeMode, mode]);

	/** Initialize analytical tools */
	useEffect(() => {
		if (sessionLoaded) {
			analyticalTools.forEach((tool) => {
				if (tool.integrationId) {
					if (tool.type === ANALYTICAL_TOOL_TYPE.GOOGLE_ANALYTICS) {
						initializeGA(tool.integrationId);
						sendGAPageView();
					} else if (tool.type === ANALYTICAL_TOOL_TYPE.HOTJAR) {
						initializeHotjar(tool.integrationId);
					} else if (tool.type === ANALYTICAL_TOOL_TYPE.VWO) {
						initializeWVO(tool.integrationId);
					} else if (tool.type === ANALYTICAL_TOOL_TYPE.YANDEX_METRICA) {
						initializeYandexMetrica(tool.integrationId);
					}
				}
			});
		}
	}, [sessionLoaded]);

	/** Initialize message events */
	useEffect(() => {
		try {
			sendPostMessageToParent({ eventName: "vs--customization-ready" });
		} catch (ex) {
			console.log(ex);
		}
		window.addEventListener(
			"message",
			(e) => {
				const d = e.data;
				if (d) {
					if (d.eventName === "vs--customization") {
						let properties = d.data;
						if (!Array.isArray(properties) && typeof properties === "object") {
							const arr = [];
							Object.keys(properties).forEach((prop) => {
								arr.push({ key: prop, value: properties[prop] });
							});
							properties = arr;
						}
						generateCssFromProperties(properties, "vs--customization-css");
					} else if (d.eventName === "vs--customization-mobile-logo") {
						dispatch(setLogoIdAction(d.data));
					}
				}
			},
			false
		);
	}, []);

	/** Detect online/offline connection */
	useEffect(() => {
		const onOnline = () => {
			console.log("online");
		};

		const onOffline = () => {
			console.log("offline");
		};

		window.addEventListener("online", onOnline);
		window.addEventListener("offline", onOffline);

		return () => {
			window.removeEventListener("online", onOnline);
			window.removeEventListener("offline", onOffline);
		};
	}, []);

	/** Check if live and upcomings contains duplicates, then reload */
	useTaskScheduler(() => {
		let hasDuplicate = false;

		const { possibleSameStartTime, possibleSameEventId } = liveAndUpcomings.reduce(
			(acc, lau) => {
				acc.possibleSameStartTime.push(lau.startTime);
				acc.possibleSameEventId.push(lau.id);
				return acc;
			},
			{ possibleSameStartTime: [], possibleSameEventId: [] }
		);

		const hasEventsWithSameStartTime = new Set(possibleSameStartTime).size < liveAndUpcomings.length;
		const hasEventsWithSameEventId = new Set(possibleSameEventId).size < liveAndUpcomings.length;
		if (hasEventsWithSameStartTime || hasEventsWithSameEventId) {
			hasDuplicate = true;
		}

		if (!hasDuplicate) {
			const duplicateStatuses = [GAME_STATUSES.STARTED, GAME_STATUSES.CLOSE_FOR_BETTING, GAME_STATUSES.PREAMBLE_STARTED];
			const hasMultipleEventsWithNewOrPreambleState = liveAndUpcomings.filter((e) => duplicateStatuses.includes(e.status)).length > 1;
			if (hasMultipleEventsWithNewOrPreambleState) {
				hasDuplicate = true;
			}
		}

		if (hasDuplicate) {
			dispatch(liveAndUpcomingsThunk(currentGameType));
		}
	}, TASK_SCHEDULER_TIME_PERIODS.LIVE_AND_UPCOMMINGS);

	return <Fragment />;
};

export default Main;
