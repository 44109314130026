import ApiUrls from "constants/api.constants";
import httpService from "services/http";

import { SCHEDULED_GAME_CATEGORY } from "constants/game.constants";

const sessionService = {
	createSession(user, sign, signal) {
		return httpService.post({
			path: ApiUrls.CREATE_SESSION,
			queryData: { gameCategory: SCHEDULED_GAME_CATEGORY },
			requestBody: { ...user },
			signal,
			configs: {
				headers: { "X-REQUEST-SIGN": sign }
			}
		});
	},

	createStreamAccess(guid, signal) {
		return httpService.post({
			path: ApiUrls.CREATE_STREAM_ACCESS,
			requestBody: { guid },
			signal,
			configs: {
				headers: { "X-REQUEST-SIGN": sign }
			}
		});
	},

	authenticate(sessionId, signal) {
		return httpService.post({
			path: ApiUrls.AUTHENTICATE,
			signal,
			requestBody: { sessionId }
		});
	},

	refreshToken(refreshToken, config = {}, signal) {
		const { headers, ...restConfig } = config;

		return httpService.post({
			path: ApiUrls.REFRESH_TOKEN,
			signal,
			requestBody: { refreshToken },
			configs: {
				headers: { ...headers, grant_type: "refresh_token" },
				...restConfig
			}
		});
	},

	details(signal) {
		return httpService.get({
			path: ApiUrls.GET_SESSION_DETAILS,
			signal
		});
	},

	streamConfiguration(id, fromStream, signal) {
		return httpService.get({
			path: ApiUrls.GET_STREAM_CONFIGURATION,
			queryData: { id },
			signal,
			configs: {
				headers: { stream: fromStream }
			}
		});
	},

	switchToRealMode(signal) {
		return httpService.post({
			path: ApiUrls.REAL_MODE,
			signal
		});
	},

	saveSettings(settings, signal) {
		return httpService.post({
			path: ApiUrls.SAVE_SETTINGS,
			signal,
			requestBody: settings
		});
	}
};

export default sessionService;
