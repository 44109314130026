import { createAsyncThunk } from "@reduxjs/toolkit";

import gameService from "services/api/game";

import { sliceName, initialState } from "./initialState";
import { selectSessionGames } from "../auth/selectors";

import runMarketUtilsFunction from "utils/markets/run";
import delay from "utils/delay";

import { GAME_EVENT_TYPE } from "constants/game.constants";

export const getEventThunk = createAsyncThunk(`${sliceName}/getEvent`, async ({ id, gameType }, { signal, getState }) => {
	const response = await gameService.event(id, signal);
	const data = response.data.value;

	const state = getState();
	const games = selectSessionGames(state);

	const gameRtps = games.find((game) => game.type === gameType)?.rtPs;

	const matchData = {
		id: data.id,
		event: data,
		markets: (
			[GAME_EVENT_TYPE.EVENT, GAME_EVENT_TYPE.WEEK].includes(data.type) && data.markets
				? runMarketUtilsFunction(
					"makeAllMarkets",
					[
						data.markets,
						data.gameData,
						gameRtps,
						gameType
					],
					gameType
				)
				: {}
		)
	};

	return matchData;
});

export const getEventInBackgroundThunk = createAsyncThunk(`${sliceName}/getEventInBackground`, async ({ id, gameType }, { signal, getState }) => {
	const response = await gameService.event(id, signal);
	const data = response.data.value;

	const state = getState();
	const games = selectSessionGames(state);

	const gameRtps = games.find((game) => game.type === gameType)?.rtPs;

	const matchData = {
		id: data.id,
		event: data,
		markets: (
			[GAME_EVENT_TYPE.EVENT, GAME_EVENT_TYPE.WEEK].includes(data.type) && data.markets
				? runMarketUtilsFunction(
					"makeAllMarkets",
					[
						data.markets,
						data.gameData,
						gameRtps,
						gameType
					],
					gameType
				)
				: {}
		)
	};

	return matchData;
});

export const getEventFromCacheThunk = createAsyncThunk(`${sliceName}/getEventFromCache`, async (_, { signal }) => {
	const response = await delay(100);

	return response;
});

export const liveAndUpcomingsThunk = createAsyncThunk(`${sliceName}/liveAndUpcomings`, async (gameType, { signal }) => {
	const response = await gameService.liveAndUpcomings(gameType, signal);
	return response.data.value;
});

export const resultsThunk = createAsyncThunk(`${sliceName}/results`, async ({ gameType, filters }, { signal }) => {
	const initialFilters = initialState.results.filters;

	const response = await gameService.results({ gameType, filters: filters ?? initialFilters }, signal);

	return { data: response.data.value, gameType };
});

export const addResultThunk = createAsyncThunk(`${sliceName}/addResult`, async ({ gameType, filters }, { signal }) => {
	const response = await gameService.results({ gameType, filters }, signal);

	return response.data.value;
});

export const lastResultsThunk = createAsyncThunk(`${sliceName}/lastResults`, async (gameType, { signal }) => {
	const response = await gameService.lastResults(gameType, signal);

	return response.data.value;
});

export const getSeasonResultsThunk = createAsyncThunk(`${sliceName}/getSeasonResults`, async (id, { signal }) => {
	const response = await gameService.seasonResults(id, signal);

	return { data: response.data.value, id };
});