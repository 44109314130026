import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Modal from "components/ui/modal";

import ScrollBar from "components/ui/scrollBar";
import KenoBalls from "components/keno/bets/kenoBalls";

import useAppDispatch from "hooks/store/useAppDispatch";
import useAppSelector from "hooks/store/useAppSelector";

import useRebet from "hooks/betslip/useRebet";

import { clearBetslipResultsAction, setShowMobileBetSlipsModalAction } from "store/slices/betslip/actions";
import { setHistoryOpenedBetSlipIdAction } from "store/slices/betHistory/actions";
import { getCurrentBetsThunk } from "store/slices/keno/thunks";
import { 
	selectIsSoundOn, 
	selectIsQuickBetEnabled
} from "store/slices/auth/selectors";
import { selectBetslipLastResults } from "store/slices/betslip/selectors";
import { selectCurrentGameType } from "store/slices/game/selectors";

import { BET_STATE, BETSLIP_MODES } from "constants/betslip.constants";
import { GAME_TYPE, GAME_TYPE_ICON_MAPPER, KENO_BALL_SIZE } from "constants/game.constants";
import Paths from "constants/path.constants";
import { sendPostMessageToParent } from "utils/iframe";

import { CLIENT_API } from "constants/integration.constants";
import runMarketUtilsFunction from "utils/markets/run";
import { isRacingGame, makeCurrencyText, playAudio, isSeasonGame, buildPathToStaticFolderOfCDN } from "utils/common";
import { navigateToPage } from "utils/navigate";
import { getBetEventName } from "utils/bets";
import useGlobalVariables from "hooks/useGlobalVariables";

/* Betsliip Won Popup Component */
const BetslipWonPopup = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const isSound = useAppSelector(selectIsSoundOn);
	const isQuickBetEnabled = useAppSelector(selectIsQuickBetEnabled);
	const bets = useAppSelector(selectBetslipLastResults);
	const currentGameType = useAppSelector(selectCurrentGameType);

	const reBet = useRebet();

	const dispatch = useAppDispatch();

	const [showPopup, setShowPopup] = useState(bets.length > 0);
	const { isMobile } = useGlobalVariables();

	/** Show popup when, bets added */
	useEffect(() => {
		if (bets.length > 0) setShowPopup(true);
	}, [bets.length]);

	/** Get currency for total win
	 * @function
	 * @returns {object}
	 * @memberof BetslipWonPopup
	 */
	const getTotalWinCurrency = () => {
		const currency = { code: null, decimalCount: 0 };
		const filteredBets = bets.filter((bet) => [BET_STATE.WON].includes(bet.status));
		for (let i = 0; i < filteredBets.length; i++) {
			if (currency.code === null) {
				currency.code = filteredBets[i].currencyId;
				currency.decimalCount = filteredBets[i].decimalCount;
			}
			if (filteredBets[i].currencyId !== "FUN") {
				currency.code = filteredBets[i].currencyId;
				currency.decimalCount = filteredBets[i].decimalCount;
				break;
			}
		}
		return currency;
	};

	/** Get total win
	 * @function
	 * @returns {number}
	 * @memberof BetslipWonPopup
	 */
	const getTotalWin = () => {
		const totalBetsCurrency = getTotalWinCurrency();
		const betsTotal = bets
			.filter((bet) => {
				const wonIncludes = [BET_STATE.WON].includes(bet.status);
				if (wonIncludes && totalBetsCurrency.code !== "FUN") {
					return bet.currencyId !== "FUN";
				}
				return wonIncludes;
			})
			.reduce((a, b) => a + (b.winning || 0), 0);
		return betsTotal;
	};

	/** Render row
	 * @function
	 * @param {object} bet - bet
	 * @param {number} index - the index in bets array
	 * @returns {JSX}
	 * @memberof BetslipWonPopup
	 */
	const renderBet = (bet, index) => {
		const team1 = bet?.gameData?.team1;
		const team2 = bet?.gameData?.team2;

		return (
			<div className="vs--betslip-results-list-item vs--mb-8 vs--pl-8 vs--pr-8 vs--pt-8 vs--pb-8" key={index}>
				<div className="vs--betslip-results-list-item-inner">
					<div className="vs--flex vs--justify-between vs--align-center vs--mb-8">
						<h6 className="vs--title-white vs--font-normal vs--font-medium vs--flex-equal vs--flex vs--align-center">
							{bet.betSlipType === BETSLIP_MODES.SINGLE ? <i className={GAME_TYPE_ICON_MAPPER[bet.gameType] ? `${GAME_TYPE_ICON_MAPPER[bet.gameType]} vs--mr-8` : null} /> : null}

							{bet.betSlipType === BETSLIP_MODES.SINGLE
								? getBetEventName({
									...bet,
									gameData: bet.gameData
										? {
											venue: bet.gameData.venue,
											team1: bet.gameData.team1
												? {
													countryCode: bet.gameData.team1
												}
												: undefined,
											team2: bet.gameData.team2
												? {
													countryCode: bet.gameData.team2
												}
												: undefined
										}
										: null
								})
								: t("bet.multi")}
						</h6>
						{bet.gameType === GAME_TYPE.KENO ? (
							<Fragment>
								<span className="vs--betslip-results-list-item-more vs--pl-8 vs--pr-8 vs--title-gray-80 vs--font-normal vs--font-regular" onClick={() => handleMultiClick(bet.betSlipId, bet.betTime)}>
									{`${bet.betsCount > 1 ? " +" + (bet.betsCount - 1) : ""}`}
								</span>
								<KenoBalls balls={bet.oddId.split(":").map((b) => Number(b))} size={KENO_BALL_SIZE.SMALL} showWinner={true} winnerBalls={bet?.gameData?.scenes ?? []} />
							</Fragment>
						) : (
							<Fragment>
								{bet.winning > 0 && (
									<b className="vs--title vs--font-normal vs--font-medium vs--betslip-results-list-item-winning">
										<span className="vs--title-gray-80 vs--font-normal vs--font-regular">{t("bet.won") + ": "}</span>
										<span className="vs--title-gray-80 vs--text-ltr">{makeCurrencyText(bet.winning, { decimalCount: bet.decimalCount, code: bet.currencyId })}</span>
									</b>
								)}
							</Fragment>
						)}
					</div>
					{bet.gameType === GAME_TYPE.KENO ? (
						<div className="vs--flex vs--justify-between vs--align-end">
							<span className="vs--title-gray-80 vs--font-normal vs--font-regular vs--betslip-results-list-item-amount">{`${t("bet.stake")}: ${makeCurrencyText(bet.amount, { decimalCount: bet.decimalCount, code: bet.currencyId })}`}</span>
							<div>
								{bet.gameType === GAME_TYPE.KENO ? (
									<Fragment>
										{bet.winning > 0 && (
											<b className="vs--title-gray-80 vs--font-normal vs--font-medium vs--betslip-results-list-item-winning">
												<span className="vs--title vs--font-normal vs--font-regular">{t("bet.won") + ": "}</span>
												<span className="vs--text-ltr">{makeCurrencyText(bet.winning, { decimalCount: bet.decimalCount, code: bet.currencyId })}</span>
											</b>
										)}
									</Fragment>
								) : (
									<Fragment>
										<span
											className="vs--title-gray-80 vs--font-normal vs--font-regular"
											dangerouslySetInnerHTML={{
												__html: runMarketUtilsFunction(
													"makeGroupTitle",
													[
														{
															period: bet.period,
															group: bet.market,
															team1: team1 ?? "Team 1",
															team2: team2 ?? "Team 2",
															argument: bet.argument,
															outcome: bet.odd,
															gameType: bet.gameType
														}
													],
													bet.gameType
												)
											}}
										></span>
										<span className="vs--title-gray-80 vs--font-normal vs--font-regular">
											:
											{` ${runMarketUtilsFunction(
												"makeWinnerMarketName",
												[
													[GAME_TYPE.FOOTBALL_SINGLE_MATCH, GAME_TYPE.PENALTY_SHOOTOUT].includes(bet.gameType) || isSeasonGame(bet.gameType)
														? {
															group: bet.market,
															team1: team1 ?? "Team 1",
															team2: team2 ?? "Team 2",
															argument: bet.argument,
															outcome: bet.odd,
															gameType: bet.gameType
														}
														: isRacingGame(bet.gameType)
															? {
																group: bet.market,
																outcome: bet.odd,
																gameData: bet.gameData,
																gameType: bet.gameType
															}
															: {},
													true
												],
												bet.gameType
											)} `}
										</span>
										<span className="vs--betslip-results-list-item-more vs--pl-8 vs--title-gray-80 vs--font-normal vs--font-regular" onClick={() => handleMultiClick(bet.betSlipId, bet.betTime)}>
											{`${bet.betsCount > 1 ? " +" + (bet.betsCount - 1) : ""}`}
										</span>
									</Fragment>
								)}
							</div>
						</div>
					) : (
						<div className="vs--flex vs--justify-between vs--align-end">
							<div className="vs--betslip-results-list-item-amount-bet vs--flex-equal ">
								{bet.gameType === GAME_TYPE.KENO ? (
									<Fragment>
										{bet.winning > 0 && (
											<b className="vs--title-gray-80 vs--font-normal vs--font-medium vs--betslip-results-list-item-winning">
												<span className="vs--title vs--font-normal vs--font-regular">{t("bet.won") + ": "}</span>
												<span className="vs--text-ltr">{makeCurrencyText(bet.winning, { decimalCount: bet.decimalCount, code: bet.currencyId })}</span>
											</b>
										)}
									</Fragment>
								) : (
									<Fragment>
										<span
											className="vs--title-gray-80 vs--font-normal vs--font-regular"
											dangerouslySetInnerHTML={{
												__html: runMarketUtilsFunction(
													"makeGroupTitle",
													[
														{
															period: bet.period,
															group: bet.market,
															team1: team1 ?? "Team 1",
															team2: team2 ?? "Team 2",
															argument: bet.argument,
															outcome: bet.odd,
															gameType: bet.gameType
														}
													],
													bet.gameType
												)
											}}
										></span>
										<span className="vs--title-gray-80 vs--font-normal vs--font-regular">
											:
											{` ${runMarketUtilsFunction(
												"makeWinnerMarketName",
												[
													[GAME_TYPE.FOOTBALL_SINGLE_MATCH, GAME_TYPE.PENALTY_SHOOTOUT].includes(bet.gameType) || isSeasonGame(bet.gameType)
														? {
															group: bet.market,
															team1: team1 ?? "Team 1",
															team2: team2 ?? "Team 2",
															argument: bet.argument,
															outcome: bet.odd,
															gameType: bet.gameType
														}
														: isRacingGame(bet.gameType)
															? {
																group: bet.market,
																outcome: bet.odd,
																gameData: bet.gameData,
																gameType: bet.gameType
															}
															: {},
													true
												],
												bet.gameType
											)} `}
										</span>
										<span className="vs--betslip-results-list-item-more vs--pl-8 vs--title-gray-80 vs--font-normal vs--font-regular" onClick={() => handleMultiClick(bet.betSlipId, bet.betTime)}>
											{`${bet.betsCount > 1 ? " +" + (bet.betsCount - 1) : ""}`}
										</span>
									</Fragment>
								)}
							</div>
							<span className="vs--title-gray-80 vs--font-normal vs--font-regular vs--betslip-results-list-item-amount">{`${t("bet.stake")}: ${makeCurrencyText(bet.amount, { decimalCount: bet.decimalCount, code: bet.currencyId })}`}</span>
						</div>
					)}
				</div>
			</div>
		)
	};

	/** Function fire on multi bet click
	 * @function
	 * @param {number} id
	 * @param {string} betTime
	 * @description navigate to history page, and opens multi bet details
	 * @memberOf BetslipWonPopup
	 */
	const handleMultiClick = (id, betTime) => {
		if (!isMobile) {
			dispatch(setHistoryOpenedBetSlipIdAction(id));
			navigateToPage(navigate, Paths.HISTORY);
		} else {
			navigateToPage(navigate, Paths.HISTORY_SINGLE.replace(":id", id) + "?betTime=" + betTime);
		}
		handlePopupClose();
	};

	/** Function to close popup
	 * @function
	 * @memberOf BetslipWonPopup
	 */
	const handlePopupClose = () => {
		setShowPopup(false);
		setTimeout(() => {
			dispatch(clearBetslipResultsAction());
			if (currentGameType === GAME_TYPE.KENO) {
				dispatch(getCurrentBetsThunk());
			}
		}, 200);
	};

	/** Function which fires on rebet button click
	 * @function
	 * @memberOf BetslipWonPopup
	 */
	const handleReBet = () => {
		reBet((betsCount) => {
			if (isMobile && betsCount > 1) {
				dispatch(setShowMobileBetSlipsModalAction(true));
			}
		});
		handlePopupClose();
	};

	const totalWin = getTotalWin();

	/** Play sound notification, when there is won bet */
	useEffect(() => {
		if (showPopup && totalWin > 0) {
			if (isMobile && isSound) {
				playAudio(buildPathToStaticFolderOfCDN("media/won.mp3"))
			}

			sendPostMessageToParent({ type: CLIENT_API.WIN, win: totalWin });
		}
	}, [showPopup, isMobile]);

	return (
		<Modal
			coordinates={isMobile ? { clientX: "50vw", clientY: "50vh", topOffset: "60px" } : undefined}
			headerContent={
				totalWin > 0 ? (
					<span className="vs--title vs--font-medium">
						{t("bet.youWon")} <b className="vs--title-brand vs--text-ltr">{makeCurrencyText(totalWin, getTotalWinCurrency())}</b>
					</span>
				) : (
					<span className="vs--title vs--font-medium">{t("bet.betterLuckNextTime")}</span>
				)
			}
			isOpen={showPopup}
			maskClosable={false}
			onCancel={handlePopupClose}
			classNames={{ content: "vs--modal-wrapper vs--betslip-results-modal" }}
			closeIcon={null}
		>
			<div className="vs--betslip-results vs--flex vs--flex-col vs--flex-equal">
				<ScrollBar className="vs--betslip-results-inner vs--flex-equal" vertical={true}>
					{totalWin > 0 ? (
						<div className="vs--betslip-results-list">
							<h2 className="vs--title-green vs--font-big vs--font-medium vs--mb-8 vs--pt-8 vs--ml-8">{t("bet.won")}</h2>
							<div className="vs--betslip-results-list">{bets.filter((bet) => [BET_STATE.WON].includes(bet.status)).map((bet, index) => renderBet(bet, index))}</div>
						</div>
					) : null}
					{bets.filter((bet) => [BET_STATE.LOST].includes(bet.status)).length > 0 ? (
						<div className="vs--betslip-results-list-lost">
							<h2 className="vs--title-red vs--font-big vs--font-medium vs--mb-8 vs--pt-8 vs--ml-8">{t("bet.lost")}</h2>
							<div className="vs--betslip-results-list">{bets.filter((bet) => [BET_STATE.LOST].includes(bet.status)).map((bet, index) => renderBet(bet, index))}</div>
						</div>
					) : null}
				</ScrollBar>
				<div className="vs--flex vs--align-center vs--justify-center vs--mt-10">
					{!isQuickBetEnabled && bets.some((b) => b.gameType === currentGameType && !isSeasonGame(b.gameType) && currentGameType !== GAME_TYPE.KENO) && (
						<button type="button" className="vs--button vs--button-rebet vs--pt-8 vs--pb-8 vs--pl-16 vs--pr-16 vs--mr-16 vs--font-normal" onClick={handleReBet}>
							{t("common.reBet")}
						</button>
					)}
					<button type="button" className="vs--button vs--pt-8 vs--pb-8 vs--pl-16 vs--pr-16 vs--font-normal" onClick={handlePopupClose}>
						{t("common.ok")}
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default BetslipWonPopup;
