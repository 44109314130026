import { sliceName } from "./initialState";

import { isRTL } from "utils/common";
import { getCurrentSettings } from "utils/settings";

const emptyArray = [];
const emptyObj = {};

export const selectSessionLoaded = (state) => state[sliceName].sessionLoaded;
export const selectSessionFailed = (state) => state[sliceName].sessionFailed;
export const selectTranslationsLoaded = (state) => state[sliceName].translationsLoaded;
export const selectSessionId = (state) => state[sliceName].sessionId;
export const selectSession = (state) => state[sliceName].session;
export const selectPlayer = (state) => state[sliceName].player;
export const selectSwitchingToRealMode = (state) => state[sliceName].switchingToRealMode;
export const selectIsSessionUpdating = (state) => state[sliceName].isSessionUpdating;

export const selectComboBoostSettings = (state) => selectSession(state).comboBoostSettings;
export const selectIsAuthorized = state => selectSession(state).isAuthorized;
export const selectIsAnonymous = state => selectSession(state).isAnonymous;
export const selectIsDemoSession = (state) => selectSession(state).isDemo;
export const selectSessionLanguageId = (state) => selectSession(state).languageId;
export const selectIsRtl = (state) => isRTL(selectSessionLanguageId(state));
export const selectSessionProjectId = (state) => selectSession(state).projectId;
export const selectSessionPartnerId = (state) => selectSession(state).partnerId;
export const selectSessionMaintenanceMode = (state) => selectSession(state).maintenanceMode;
export const selectSessionBalance = (state) => selectSession(state).balance ?? 0;
export const selectLoginConfiguration = (state) => selectSession(state)?.loginConfiguration ?? emptyObj;
export const selectSessionExitUrl = (state) => selectSession(state).exitUrl;
export const selectSessionSurveys = (state) => selectSession(state).surveys;
export const selectUserId = (state) => selectPlayer(state).userId;
export const selectSessionGames = (state) => selectSession(state).games ?? emptyArray;
export const selectSessionCurrency = (state) => selectSession(state).currency ?? emptyObj;
export const selectSessionCurrencyDecCount = (state) => selectSessionCurrency(state).decimalCount ?? 2;
export const selectSessionBonuses = state => selectSession(state).bonuses ?? emptyArray;
export const selectSessionCurrentBonus = state => selectSession(state).bonuses?.[0] ?? emptyObj;
export const selectIsSplitStakeEnabled = state => selectSession(state).isSplitStakeEnabled;
export const selectAnalyticalTools = state => selectSession(state).analyticalTools ?? emptyArray;
export const selectIsHideHeaderInMobileFrame = state => selectSession(state).isHideHeaderInMobileFrame;
export const selectSessionLogoId = state => selectSession(state).logoId;

export const selectSessionSettings = (state) => getCurrentSettings(selectSession(state));
export const selectIsAnimation = state => getCurrentSettings(selectSession(state)).isAnimation;
export const selectHideRebetPopup = (state) => getCurrentSettings(selectSession(state)).hideRebetPopup;
export const selectIsSoundOn = (state) => getCurrentSettings(selectSession(state)).isSound;
export const selectOddFormat = (state) => getCurrentSettings(selectSession(state)).oddFormat;
export const selectIsQuickBetEnabled = (state) => getCurrentSettings(selectSession(state)).isQuickBet;
export const selectQuickBetAmount = (state) => getCurrentSettings(selectSession(state)).quickBetAmount;
