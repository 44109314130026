import { createAsyncThunk } from "@reduxjs/toolkit";

import gameService from "services/api/game";
import statisticsService from "services/api/statistics";

import { sliceName } from "./initialState";
import { selectSessionGames } from "store/slices/auth/selectors";
import { selectLiveAndUpcomingsData } from "store/slices/game/selectors";

import runMarketUtilsFunction from "utils/markets/run";

import { SELECTED_SEASON_GAMES, PREDICATE_MULTIPLIER_OF_SEASON_GAMES } from "constants/game.constants";

export const getSeasonMarketsThunk = createAsyncThunk(`${sliceName}/getSeasonMarkets`, async ({ id, selectedSeasonGames }, { signal, getState }) => {
	const response = await gameService.event(id, signal);
	const data = response.data.value;

	const state = getState();
	const games = selectSessionGames(state);
	const liveAndUpcomings = selectLiveAndUpcomingsData(state);
	const index = liveAndUpcomings.findIndex((lau) => lau.seasonId === response.data.value.id);
	const currentGame = games.find((g) => g.type === data.gameType);
	const rtps = currentGame?.rtPs ?? [];

	let markets = runMarketUtilsFunction(
		"makeAllMarkets",
		[
			data?.markets ?? [],
			null,
			rtps,
			data.gameType
		],
		data.gameType
	);
	markets = markets.fulltime;
	markets = markets.map((m) => ({
		...m,
		bets: m.bets.filter((b) => !b.disabled).sort((b1, b2) => (b1.factor < b2.factor ? -1 : 1))
	}));

	let seasonSelector = SELECTED_SEASON_GAMES.NONE;
	if (!selectedSeasonGames) {
		if (index !== -1) {
			seasonSelector = index < PREDICATE_MULTIPLIER_OF_SEASON_GAMES[data.gameType] ? SELECTED_SEASON_GAMES.CURRENT : SELECTED_SEASON_GAMES.NEXT;
		} else {
			markets = [];
		}
	} else {
		seasonSelector = selectedSeasonGames;
	}

	const dataForUpdate = {
		markets: {
			...state?.special?.markets,
			[seasonSelector]: markets
		}
	};
	if (seasonSelector === SELECTED_SEASON_GAMES.CURRENT) {
		dataForUpdate.seasonId = data.id;
		dataForUpdate.status = data.status;
	}

	return dataForUpdate;
});

export const getSeasonStandingsThunk = createAsyncThunk(`${sliceName}/getSeasonStandings`, async (id, { signal }) => {
	const response = await statisticsService.getSeasonStandings(id, signal);

	return response.data.value;
});

export const getSeasonStructureThunk = createAsyncThunk(`${sliceName}/getSeasonStructure`, async (id, { signal }) => {
	const response = await gameService.seasonResults(id, signal);

	return response.data.value;
});