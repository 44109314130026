import { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import AreYouSure from 'components/ui/areYouSure';
import ScrollBar from 'components/ui/scrollBar';
import KenoBalls from 'components/keno/bets/kenoBalls';
import Tooltip from 'components/ui/tooltip';

import { formatDateTime } from 'utils/dateTime';
import runMarketUtilsFunction from 'utils/markets/run';
import { numberWithSpaces, makeCurrencyText } from 'utils/common';
import { getBetEventName } from 'utils/bets';
import { getCurrentSettings } from "utils/settings";

import { BETSLIP_MODES, BET_STATE } from 'constants/betslip.constants';
import { GAME_TYPE_ICON_MAPPER, GAME_TYPE, KENO_BALL_SIZE } from 'constants/game.constants';
import { ODD_FORMATS } from "constants/market.constants";

import { cancelSingleBetThunk } from 'store/slices/betslip/thunks';
import { selectOddFormat } from 'store/slices/auth/selectors';
import { selectPendingBets } from 'store/slices/betslip/selectors';

import useGlobalVariables from 'hooks/useGlobalVariables';
import useAppDispatch from 'hooks/store/useAppDispatch';
import useAppSelector from 'hooks/store/useAppSelector';

/* Pending Bet Details Component */
const PendingBetDetails = ({
	id,
	handleBack
}) => {

	const { t } = useTranslation();
	const { isMobile } = useGlobalVariables();
	const pendings = useAppSelector(selectPendingBets);
	const oddFormat = useAppSelector(selectOddFormat);

	const dispatch = useAppDispatch();

	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const pendingBet = pendings.find(p => p.id === id)

	/** Function fires on cancelation modal close
		  * @function
		  * @description close cancelation modal
		  * @memberOf PendingBetDetails
	 */
	const onModalCancel = () => {
		setShowDeleteModal(false);
	}

	/** Function fires on "cancel bet" button click
		* @function
		* @description open cancelation popup
		* @memberOf PendingBetDetails
  */
	const cancel = () => {
		dispatch(cancelSingleBetThunk({ id, betId: showDeleteModal }));
		setShowDeleteModal(false);
	}

	/** Back to pending when pending status changed to another */
	useEffect(() => {
		if (!pendingBet) handleBack()
	}, [pendingBet])

	return (
		<div className="vs--pending-details vs--flex vs--flex-equal vs--flex-col">
			<div
				className="vs--pending-details-header vs--flex vs--align-center vs--justify-center vs--pl-8 vs--pr-8"
			>
				<div className="vs--flex vs--flex-equal">
					<i className="ic_back vs--font-bigest vs--cursor-pointer" onClick={handleBack} />
				</div>
				<span className="vs--title-white vs--font-normal vs--font-medium">
					{`${t('bet.betslipId')} ${id}`}
				</span>
				<div className='vs--flex vs--flex-equal'></div>
			</div>
			<ScrollBar
				className="vs--pending-details-content vs--pl-8 vs--pr-8 vs--pb-8 vs--pt-16"
				vertical={true}
			>
				{
					(pendingBet?.bets ?? []).map(bet => (
						<div className="vs--pending-item vs--mb-8" key={bet.id}>
							<div className="vs--pending-item-head vs--flex vs--justify-center vs--align-center vs--mb-1 vs--pl-8 vs--pr-8">
								<div className="vs--flex vs--align-center">
									<i className={GAME_TYPE_ICON_MAPPER[bet.gameType] || null} />
									<span className="vs--title-white vs--font-small vs--font-regular vs--pl-8 vs--pr-8">
										{
											getBetEventName(bet)
										}
									</span>
								</div>
							</div>
							<div className="vs--pending-item-content vs--pl-8 vs--pr-8 vs--pb-8 vs--pt-8" >
								<div className="vs--flex vs--justify-between vs--align-center vs--mb-8" >
									<Tooltip placement='top-start' title={t('common.date')}>
										<span className="vs--title-gray-80 vs--font-normal vs--font-regular vs--pr-8">
											{t('common.date')}
										</span>
									</Tooltip>
									<Tooltip placement="top-end" title={formatDateTime(bet.betTime)}>
										<b className="vs--title-white vs--font-normal vs--font-regular vs--text-cut">
											{formatDateTime(bet.betTime)}
										</b>
									</Tooltip>
								</div>
								<div className="vs--flex vs--justify-between vs--align-center vs--mb-8" >
									<Tooltip placement='top-start' title={t('bet.betId')}>
										<span className="vs--title-gray-80 vs--font-normal vs--font-regular vs--pr-8">{t('bet.betId')}</span>
									</Tooltip>
									<Tooltip placement='top-end' title={bet.id}>
										<b className="vs--title-white vs--font-normal vs--font-regular vs--text-cut">
											{bet.id}
										</b>
									</Tooltip>
								</div>
								<div className="vs--flex vs--justify-between vs--align-center vs--mb-8" >
									<Tooltip placement='top-start' title={bet.gameType === GAME_TYPE.KENO ? t('common.roundId') : t('common.eventId')}>
										<span className="vs--title-gray-80 vs--font-normal vs--font-regular vs--pr-8">{bet.gameType === GAME_TYPE.KENO ? t('common.roundId') : t('common.eventId')}</span>
									</Tooltip>
									<Tooltip placement='top-end' title={bet.eventId}>
										<b className="vs--title-white vs--font-normal vs--font-regular vs--text-cut">
											{bet.eventId}
										</b>
									</Tooltip>
								</div>
								<div className="vs--flex vs--justify-between vs--align-center vs--mb-8" >
									<Tooltip placement='top-start' title={t('bet.startsAt')}>
										<span className="vs--title-gray-80 vs--font-normal vs--font-regular vs--pr-8">{t('bet.startsAt')}</span>
									</Tooltip>
									<Tooltip placement='top-end' title={formatDateTime(bet.startTime)}>
										<b className="vs--title-white vs--font-normal vs--font-regular vs--text-cut">
											{formatDateTime(bet.startTime)}
										</b>
									</Tooltip>
								</div>
								<div className="vs--flex vs--justify-between vs--align-center vs--mb-8" >
									<Tooltip placement='top-start' title={t('bet.bet')}>
										<span className="vs--title-gray-80 vs--font-normal vs--font-regular vs--pr-8">{t('bet.bet')}</span>
									</Tooltip>
									{
										bet.gameType === GAME_TYPE.KENO
											? (
												<KenoBalls
													balls={bet.outcome.split(":").map(b => Number(b))}
													size={KENO_BALL_SIZE.SMALL}
												/>
											) : (
												<Tooltip placement='top-end' title={runMarketUtilsFunction("makeBetText", [bet], bet.gameType)}>
													<b
														className="vs--title-white vs--font-normal vs--font-regular vs--text-cut"
														dangerouslySetInnerHTML={{ __html: runMarketUtilsFunction("makeBetText", [bet], bet.gameType) }}
													/>
												</Tooltip>
											)
									}
								</div>
								<div className="vs--flex vs--justify-between vs--align-center vs--mb-8" >
									<Tooltip placement='top-start' title={t('bet.odds')}>
										<span className="vs--title-gray-80 vs--font-normal vs--font-regular vs--pr-8">{t('bet.odds')}</span>
									</Tooltip>
									<Tooltip placement='top-end' title={`${numberWithSpaces(runMarketUtilsFunction("convertToFormat", [bet.factor, oddFormat]))}${oddFormat === ODD_FORMATS.DECIMAL ? isMobile ? " x" : "x" : ""}`}>
										<b className="vs--title-white vs--font-normal vs--font-regular vs--text-ltr vs--text-cut">
											{`${numberWithSpaces(runMarketUtilsFunction("convertToFormat", [bet.factor, oddFormat]))}${oddFormat === ODD_FORMATS.DECIMAL ? isMobile ? " x" : "x" : ""}`}
										</b>
									</Tooltip>
								</div>
								{
									pendingBet?.type === BETSLIP_MODES.SINGLE && (
										<Fragment>
											<div className="vs--flex vs--justify-between vs--align-center vs--mb-8" >
												<Tooltip placement='top-start' title={t('bet.stake')}>
													<span className="vs--title-gray-80 vs--font-normal vs--font-regular vs--pr-8">{t('bet.stake')}</span>
												</Tooltip>
												<Tooltip placement='top-end' title={makeCurrencyText(bet.amount, { decimalCount: pendingBet.decimalCount, code: pendingBet.currencyId })}>
													<b className="vs--title-white vs--font-normal vs--font-regular vs--text-ltr vs--text-cut">
														{makeCurrencyText(bet.amount, { decimalCount: pendingBet.decimalCount, code: pendingBet.currencyId })}
													</b>
												</Tooltip>
											</div>
											<div className="vs--flex vs--justify-between vs--align-center vs--mb-8" >
												<Tooltip placement='top-start' title={t('bet.possibleWin')}>
													<span className="vs--title-gray-80 vs--font-normal vs--font-regular vs--pr-8">{t('bet.possibleWin')}</span>
												</Tooltip>
												<Tooltip placement='top-end' title={makeCurrencyText(bet.possibleWin, { decimalCount: pendingBet.decimalCount, code: pendingBet.currencyId })}>
													<b className="vs--title-brand vs--font-normal vs--font-regular vs--text-cut">
														{makeCurrencyText(bet.possibleWin, { decimalCount: pendingBet.decimalCount, code: pendingBet.currencyId })}
													</b>
												</Tooltip>
											</div>
										</Fragment>
									)
								}
							</div>
							{
								pendingBet?.type === BETSLIP_MODES.SINGLE && bet.allowCancel && bet.state === BET_STATE.PENDING && (
									<button
										type="button"
										className="vs--button vs--pending-item-cancel-bet-button vs--pt-10 vs--pb-10 vs--pl-16 vs--pr-16 vs--font-normal vs--font-medium"
										onClick={() => setShowDeleteModal(bet.id)}
									>
										{t('bet.cancelBet')}
									</button>
								)
							}
						</div>
					))
				}
			</ScrollBar>
			{
				showDeleteModal && (pendingBet?.bets ?? []).some(b => b.allowCancel)
					? (
						<AreYouSure
							onCancel={onModalCancel}
							onOk={cancel}
						/>
					)
					: null
			}
		</div>
	)
}

/** PendingBetDetails propTypes
	 * PropTypes
*/
PendingBetDetails.propTypes = {
	/** Pending bet id */
	id: PropTypes.number,
	/** Function to call on back button click */
	handleBack: PropTypes.func
}

export default PendingBetDetails;