import { createAction } from "@reduxjs/toolkit";

import { sliceName } from "./initialState";

import localStorage from "utils/localStorage";

export const setPlayerAction = createAction(`${sliceName}/setPlayer`, (player) => {
	return {
		payload: { player }
	};
});

export const setSessionFailedAction = createAction(`${sliceName}/setSessionFailed`, (sessionFailed) => {
	return {
		payload : { sessionFailed }
	};
})

export const setBonusSeenAction = createAction(`${sliceName}/setBonusSeen`);

export const setBalanceAction = createAction(`${sliceName}/setBalance`, (balance) => {
	return {
		payload : { balance }
	};
});

export const setLogoIdAction = createAction(`${sliceName}/setLogoId`, (logoId) => {
	return {
		payload : { logoId }
	};
});

export const setMaintenanceModeAction = createAction(`${sliceName}/setMaintenanceMode`);

export const setTranslationsLoadedAction = createAction(`${sliceName}/setTranslationsLoaded`);