import { Fragment, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import useAppSelector from "hooks/store/useAppSelector";

import useGlobalVariables from "hooks/useGlobalVariables";
import Loader from "components/ui/loader";
import BetSlipEmpty from "../betslip/empty";
import PendingBet from "./pendingBet";
import PendingBetDetails from "./pendingBetDetails";
import ScrollBar from "components/ui/scrollBar";

import { 
	selectPendingBets, 
	selectIsPendingsLoading, 
	selectIsBetslipCancelling 
} from "store/slices/betslip/selectors";

/* Pending Bets Tab Component */
const PendingBets = () => {
	const { t } = useTranslation();
	const { isMobile } = useGlobalVariables();
	const pendings = useAppSelector(selectPendingBets);
	const loading = useAppSelector(selectIsPendingsLoading);
	const isCanceling = useAppSelector(selectIsBetslipCancelling);

	const [currentViewingPending, setCurrentViewingPending] = useState(null);
	const ref = useRef();

	useLayoutEffect(() => {
		if (isMobile) {
			return;
		}
		if (loading) {
			return;
		}
		if (pendings.length === 0) {
			return;
		}
		if (currentViewingPending) {
			return;
		}
		if (!ref.current) {
			return;
		}

		/*
			method getBoundingClientRect is overriden in ScrollBar
			native method name is _getBoundingClientRect
		*/
		const { top } = ref.current._getBoundingClientRect();
		ref.current.style.maxHeight = `${document.body.offsetHeight - top}px`;
	}, [isMobile, loading, pendings, currentViewingPending]);

	return loading ? (
		<Loader />
	) : (
		<Fragment>
			{pendings.length > 0 ? (
				currentViewingPending ? (
					<PendingBetDetails id={currentViewingPending} handleBack={() => setCurrentViewingPending(null)} />
				) : (
					<div className="vs--rightBar-content">
					<ScrollBar className="vs--pending vs--pl-8 vs--pr-8 vs--pt-16 vs--pb-8" vertical={true} containerRef={ref}>
						{pendings.map((pending) => (
							<PendingBet pending={pending} key={pending.id} handleClick={() => setCurrentViewingPending(pending.id)} />
						))}
					</ScrollBar>
					</div>
				)
			) : (
				<div className="vs--rightBar-content">
					<BetSlipEmpty text={t("bet.pendingEmptyText")} subtext={""} />
				</div>
			)}

			{isCanceling && <Loader className="vs--pending-loader" fixed={true} />}
		</Fragment>
	);
};

export default PendingBets;
