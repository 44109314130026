import { createAction } from "@reduxjs/toolkit";

import { sliceName } from "./initialState";

export const removeFromLiveAndUpcomingsAction = createAction(`${sliceName}/removeFromLiveAndUpcomings`, (id) => {
	return {
		payload: { id }
	};
});

export const addLiveAndUpcomingsAction = createAction(`${sliceName}/addLiveAndUpcomings`, (event) => {
	return {
		payload: { event }
	};
});

export const updateFromLiveAndUpcomingsAction = createAction(`${sliceName}/updateFromLiveAndUpcomings`, (event) => {
	return {
		payload: { event }
	};
});

export const removeFromLiveAndUpcomingsBySeasonAction = createAction(`${sliceName}/removeFromLiveAndUpcomingsBySeason`, (id) => {
	return {
		payload: { id }
	};
});

export const setCurrentEventIdAction = createAction(`${sliceName}/setCurrentEventId`, (id) => {
	return {
		payload: { id }
	};
});

export const setLastResultsAction = createAction(`${sliceName}/setLastResults`, (data) => {
	return {
		payload: { data }
	};
});

export const addLastResultAction = createAction(`${sliceName}/addLastResult`, (result) => {
	return {
		payload: { result }
	};
});

export const setCurrentGameTypeAction = createAction(`${sliceName}/setCurrentGameType`, (type) => {
	return {
		payload: { type }
	};
});

export const setGameCountDownAction = createAction(`${sliceName}/setGameCountDown`, (data) => {
	return {
		payload: { data }
	};
});

export const setCurrentGameBonusBetDisabledAction = createAction(`${sliceName}/setCurrentGameBonusBetDisabled`, (isDisabled) => {
	return {
		payload: { isDisabled }
	};
});

export const setMarketsCollapsibleActiveKeysAction = createAction(`${sliceName}/setMarketsCollapsibleActiveKeys`, (keys, tab) => {
	return {
		payload: { keys, tab }
	};
});

export const updateEventAction = createAction(`${sliceName}/updateEvent`, ({ id, data, rtps }) => {
	return {
		payload: { id, data, rtps }
	};
});

export const clearSeasonResultAction = createAction(`${sliceName}/clearSeasonResult`);

export const setResultsFiltersAction = createAction(`${sliceName}/setResultsFilters`, (filters) => {
	return {
		payload: { filters }
	};
});

export const setSeasonResultsFiltersAction = createAction(`${sliceName}/setSeasonResultsFilters`, (filters) => {
	return {
		payload: { filters }
	};
});

export const clearSeasonResultsFiltersAction = createAction(`${sliceName}/clearSeasonResultsFilters`);