import {
	placeBetThunk,
	placeQuickBetThunk,
	pendingBetsThunk,
	cancelBetSlipThunk,
	cancelSingleBetThunk
} from "./thunks";

import {
	setBetslipAction,
	setStakeAction,
	updateBetStakeAction,
	setModeAction,
	setStakeModeAction,
	setRigthbarTabAction,
	setShowKeyboardAction,
	setShowMobileBetSlipsModalAction,
	addBetslipResultAction,
	clearBetslipResultsAction,
	addBetAction,
	updateMatchBetsAction,
	removeBetAction,
	removePendingAction,
	updatePendingAction,
	updatePendingBetAction,
	clearBetsAction,
	clearHiddenBetsAction,
	placeBetSuccessAction
} from "./actions";

import { filterFnUpdateMatchBets, mapperFnUpdateMatchBets } from "utils/bets";

import { GAME_STATUSES } from "constants/game.constants";

export const addBetslipCases = (builder) => {
	builder.addCase(setBetslipAction, (state, { payload }) => {
		if (payload.betslip.stake !== undefined) {
			state.stake = payload.betslip.stake;
		}
		if (payload.betslip.stakeMode !== undefined) {
			state.stakeMode = payload.betslip.stakeMode;
		}
		if (payload.betslip.mode !== undefined) {
			state.mode = payload.betslip.mode;
		}
		if (payload.betslip.bets !== undefined) {
			state.bets = payload.betslip.bets;
		}
	});

	builder.addCase(setShowMobileBetSlipsModalAction, (state, { payload }) => {
		state.showMobileBetslipsModal = payload.show;
	});
};

export const addPlaceBetCases = (builder) => {
	builder.addCase(placeBetThunk.pending, (state) => {
		state.loading = true;
	});

	builder.addCase(placeBetThunk.fulfilled, (state, { payload: data }) => {
		state.success = true;
		state.loading = false;
		state.pendings = [data, ...state.pendings];
	});

	builder.addCase(placeBetThunk.rejected, (state, { error }) => {
		state.loading = false;
	});

	builder.addCase(placeQuickBetThunk.pending, (state) => {
		state.loading = true;
	});

	builder.addCase(placeQuickBetThunk.fulfilled, (state, { payload: data }) => {
		state.success = true;
		state.loading = false;
		state.pendings = [data, ...state.pendings];
	});

	builder.addCase(placeQuickBetThunk.rejected, (state, { error }) => {
		state.loading = false;
	});

	builder.addCase(placeBetSuccessAction, (state, { payload }) => {
		state.success = payload.success;
	})
};

export const cancelBetCases = (builder) => {
	builder.addCase(cancelBetSlipThunk.pending, (state) => {
		state.isCanceling = true;
	});

	builder.addCase(cancelBetSlipThunk.fulfilled, (state) => {
		state.isCanceling = false;
	});

	builder.addCase(cancelBetSlipThunk.rejected, (state, { error }) => {
		state.isCanceling = false;
		console.log(error);
	});

	builder.addCase(cancelSingleBetThunk.pending, (state) => {
		state.isCanceling = true;
	});

	builder.addCase(cancelSingleBetThunk.fulfilled, (state) => {
		state.isCanceling = false;
	});

	builder.addCase(cancelSingleBetThunk.rejected, (state, { error }) => {
		state.isCanceling = false;
		console.log(error);
	});
};

export const addStakeCases = (builder) => {
	builder.addCase(setStakeAction, (state, { payload: { stake } }) => {
		state.stake = stake;
	});

	builder.addCase(updateBetStakeAction, (state, { payload }) => {
		state.bets = state.bets.map((b) => (b.key !== payload.key ? b : { ...b, stake: payload.stake }));
	});
}

export const addModeCases = (builder) => {
	builder.addCase(setModeAction, (state, { payload: { mode } }) => {
		state.mode = mode;
	});

	builder.addCase(setStakeModeAction, (state, { payload: { mode } }) => {
		state.stakeMode = mode;
	});
}

export const addKeyboardCases = (builder) => {
	builder.addCase(setShowKeyboardAction, (state, { payload }) => {
		state.showKeyboard = payload.show;
	});
}

export const addBetslipResultsCases = (builder) => {
	builder.addCase(addBetslipResultAction, (state, { payload }) => {
		state.betslipLastResults = [...payload.data, ...state.betslipLastResults];
	});

	builder.addCase(clearBetslipResultsAction, (state) => {
		state.betslipLastResults = [];
	});
}

export const addBetCases = (builder) => {
	builder.addCase(addBetAction, (state, { payload }) => {
		state.success = false;
		state.bets = [{ ...payload.bet, isExpired: false }, ...state.bets];
	});

	builder.addCase(updateMatchBetsAction, (state, { payload }) => {
		if (state.bets.length === 0) {
			return;
		}
		const isFinished_updateMatchBets = payload.event.status === GAME_STATUSES.FINISHED;
		const isExpired_updateMatchBets = payload.event.status === GAME_STATUSES.STARTED || payload.event.status === GAME_STATUSES.CLOSE_FOR_BETTING;

		if (!isFinished_updateMatchBets && !isExpired_updateMatchBets) {
			return;
		}

		const mapped_updateMatchBets = mapperFnUpdateMatchBets(isExpired_updateMatchBets, filterFnUpdateMatchBets(isFinished_updateMatchBets, state, payload), payload);
		if (state.bets === mapped_updateMatchBets) {
			return;
		}

		state.bets = mapped_updateMatchBets;
	})
}

export const addRemoveBetsCases = (builder) => {
	builder.addCase(removeBetAction, (state, { payload }) => {
		const updated_after_remove = state.bets.filter((bet) => (
			(payload.betId && bet.betId !== payload.betId) ||
			(payload.eventId && bet.eventId !== payload.eventId) ||
			(payload.key && bet.key !== payload.key)
		));

		state.bets = updated_after_remove;
		state.stake = updated_after_remove.length > 0 || !payload.removeStake ? state.stake : "";
	});

	builder.addCase(clearBetsAction, (state) => {
		state.bets = [];
	});

	builder.addCase(clearHiddenBetsAction, (state) => {
		state.bets = state.bets.filter((b) => !b.hidden);
	})
}

export const addPendingBetsCases = (builder) => {
	builder.addCase(pendingBetsThunk.pending, (state) => {
		state.pendingLoading = true;
	});

	builder.addCase(pendingBetsThunk.fulfilled, (state, { payload: data }) => {
		state.pendingLoading = false;
		state.pendings = data ?? [];
	});

	builder.addCase(pendingBetsThunk.rejected, (state, { error }) => {
		state.pendingLoading = false;
	});

	builder.addCase(removePendingAction, (state, { payload }) => {
		state.pendings = state.pendings.filter((p) => p.id !== payload.pending.id)
	});

	builder.addCase(updatePendingAction, (state, { payload }) => {
		state.pendings = state.pendings.map((p) => (p.id !== payload.pending.id ? p : payload.pending));
	});

	builder.addCase(updatePendingBetAction, (state, { payload }) => {
		state.pendings = state.pendings.map((p) => ({ ...p, bets: p.bets.map((b) => (b.id === payload.bet.id ? payload.bet : b)) }));
	});
}

export const addRightbarTabCases = (builder) => {
	builder.addCase(setRigthbarTabAction, (state, { payload }) => {
		state.rightbarTab = payload.tab;
	});
}