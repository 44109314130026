import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import Input from "components/ui/input";

import useAppDispatch from "hooks/store/useAppDispatch";
import useAppSelector from "hooks/store/useAppSelector";

import { setStakeAction, setShowKeyboardAction } from "store/slices/betslip/actions";
import {
	selectIsDemoSession,
	selectIsAuthorized,
	selectSessionCurrency,
	selectSessionCurrentBonus,
	selectIsSessionUpdating,
	selectIsQuickBetEnabled,
	selectQuickBetAmount,
	selectIsRtl
} from "store/slices/auth/selectors";
import { selectUseBonus } from "store/slices/bonus/selectors";
import { selectBetslipStake, selectBets, selectBetslipMode } from "store/slices/betslip/selectors";

import {
	toFixed,
	validateForAmount,
	numberWithSpaces,
	makeCurrencyText,
	isThereAtLeastOneKenoBet,
	isFactorsResultOverLimited,
	mergeClassNames
} from "utils/common";

import { BETSLIP_MODES, BETSLIP_KEEP_FOCUSED_STAKE_DATA_ATTRS, MULTI_MODE_BETS_MAX_FACTORS } from "constants/betslip.constants";
import { BONUS_TYPE, BONUS_WIN_TYPE } from "constants/bonus.constants";

import useGlobalVariables from "hooks/useGlobalVariables";
import useComboBoost from "hooks/useComboBoost";

const StakeInput = ({
	disabled = false,
	showKeyboard = false,
	focusedStakeId = null,
	setFocusedStakeId = Function.prototype,
	checkIsThereFreeAmountBonusError = Function.prototype,
	hasError = Function.prototype
}) => {
	const { t } = useTranslation();
	const { isMobile } = useGlobalVariables();
	const isQuickBetEnabled = useAppSelector(selectIsQuickBetEnabled);
	const isRTLLanguage = useAppSelector(selectIsRtl);
	const quickBetAmount = useAppSelector(selectQuickBetAmount);
	const isDemo = useAppSelector(selectIsDemoSession);
	const isAuthorized = useAppSelector(selectIsAuthorized);
	const currency = useAppSelector(selectSessionCurrency);
	const currentBonus = useAppSelector(selectSessionCurrentBonus);
	const useBonus = useAppSelector(selectUseBonus);
	const stake = useAppSelector(selectBetslipStake);
	const bets = useAppSelector(selectBets);
	const mode = useAppSelector(selectBetslipMode);
	const isSessionUpdating = useAppSelector(selectIsSessionUpdating);

	const dispatch = useAppDispatch();

	const comboBoostInfo = useComboBoost()

	const isFreeAmountBonus = useBonus && currentBonus.bonusType === BONUS_TYPE.FREEAMOUNT;

	const isRealMode = isAuthorized && !isDemo;

	// true if Mobile view, or true if user can't change stake
	const readOnly = isMobile || (useBonus && !isFreeAmountBonus);
	const isQuickBetStakeDisabled = isQuickBetEnabled ? isDemo : false;

	const numberWithSpacesConfig = isRTLLanguage ? { reverse: false, separator: "" } : undefined;

	const minLimit = (mode === BETSLIP_MODES.SINGLE ? (currency.singleMin ?? 0) : (currency.multiMin ?? 0));
	const maxLimit = (mode === BETSLIP_MODES.SINGLE ? (currency.singleMax ?? Infinity) : (currency.multiMax ?? Infinity));
	const maxPossibleWin = (mode === BETSLIP_MODES.SINGLE ? (currency.singlePossibleWinMax ?? Infinity) : (currency.multiPossibleWinMax ?? Infinity));

	const activeBets = bets.filter((bet) => !bet.isExpired);

	const isThereError = !isSessionUpdating && hasError();

	const calculatePossibleWin = () => {
		let possibleWin = 0;
		if (mode === BETSLIP_MODES.SINGLE) {
			possibleWin = activeBets.reduce((total, bet) => total + Number(bet.stake) * bet.factor - (useBonus && currentBonus.winType === BONUS_WIN_TYPE.PURE ? bet.stake : 0), 0);
		} else if (mode === BETSLIP_MODES.MULTI) {
			possibleWin = activeBets.reduce((total, bet) => total * bet.factor, 1) * stake - (useBonus ? stake : 0);
		}
		return toFixed(possibleWin, currency.decimalCount ?? 2);
	};

	const onChange = (e) => {
		const value = e.target.value.replace(/\s/g, "");
		if (validateForAmount(value, currency.decimalCount ?? 0)) {
			dispatch(setStakeAction(value));
		};
	};

	const renderPossibleWinAmount = () => {
		const possibleWinAmount = calculatePossibleWin()
		return numberWithSpaces(
			toFixed(
				window.isNaN(possibleWinAmount) ? 0 : possibleWinAmount,
				currency.decimalCount
			)
		)
	};

	useEffect(() => {
		if (isRealMode && isQuickBetEnabled && !useBonus) {
			dispatch(setStakeAction(quickBetAmount));
		}
	}, [isRealMode, isQuickBetEnabled, quickBetAmount, useBonus]);

	return (
		<>
			{(focusedStakeId === null || !isMobile) && (
				<div className="vs--flex vs--justify-between">
					<Input
						wrapperProps={
							{
								[BETSLIP_KEEP_FOCUSED_STAKE_DATA_ATTRS.BETSLIP_STAKE]: true
							}
						}
						id="vs--betslip-stake-id"
						className={
							mergeClassNames(
								"vs--betslip-stake-input vs--mt-4",
								isQuickBetEnabled && "vs--betslip-stake-input-quick-bet",
								isThereError && "vs--stake-input-error",
								showKeyboard && "vs--stake-input-with-keyboard",
								(readOnly || disabled) && "vs--stake-input-disabled"
							)
						}
						placeholder={`${t('common.min')} ${numberWithSpaces(Number(minLimit), numberWithSpacesConfig)} - ${t('common.max')} ${numberWithSpaces(Number(maxLimit), numberWithSpacesConfig)}`}
						readOnly={readOnly}
						disabled={disabled || ((useBonus && !isFreeAmountBonus) || isQuickBetStakeDisabled)}
						value={numberWithSpaces(stake)}
						onChange={isMobile ? undefined : onChange}
						onFocus={() => setFocusedStakeId(null)}
						onClick={
							isMobile ? () => {
								if (!disabled) {
									dispatch(setShowKeyboardAction(!(useBonus && !isFreeAmountBonus) && !isQuickBetStakeDisabled))
								}
							} : undefined
						}
						prefix={isQuickBetEnabled && quickBetAmount === Number(stake) ? () => <i className="ic_check vs--title-green" /> : null}
						suffix={useBonus && (
							<i className="ic_bonus vs--stake-input-bonus-icon vs--stake-input-bonus-icon-right" />
						)}
					/>
					{!isQuickBetEnabled && (
						<div className="vs--betslip-stake-possible-win vs--flex vs--flex-col vs--justify-center vs--align-end">
							<span className="vs--betslip-stake-possible-win-text vs--title-white vs--font-regular vs--font-normal">
								{
									useBonus
										? `${t("bonus.bonus")} ${t("bet.possibleWin")}:`
										: `${t("bet.possibleWin")}:`
								}
							</span>
							<div
								className="vs--flex vs--justify-end vs--align-center vs--font-small vs--title-brand"
							>
								<b className="vs--betslip-stake-possible-win-amount vs--font-medium vs--text-ltr vs--ml-2">
									{renderPossibleWinAmount()}
								</b>
								<b className="vs--font-medium vs--pl-4">
									{currency.code}
								</b>
							</div>
						</div>
					)}
				</div>
			)}

			{
				isThereError
					? (
						<div className="vs--stake-error vs--flex vs--flex-row vs--align-center vs--pl-4 vs--pr-4 vs--pt-4">
							{
								Number(stake) < minLimit && ((stake !== "" && mode === BETSLIP_MODES.MULTI) || isQuickBetEnabled)
									? (
										<span className="vs--title-red vs--font-regular vs--font-small">
											{t("bet.minBetAmountIs")}{" "}
											<span className="vs--font-regular vs--font-small vs--text-ltr">
												{makeCurrencyText(minLimit, currency)}
											</span>
										</span>
									) : Number(stake) > maxLimit && ((stake !== "" && mode === BETSLIP_MODES.MULTI) || isQuickBetEnabled)
										? (
											<span className="vs--title-red vs--font-regular vs--font-small">
												{t("bet.maxBetAmountIs")}{" "}
												<span className="vs--font-regular vs--font-small vs--text-ltr">
													{makeCurrencyText(maxLimit, currency)}
												</span>
											</span>
										)
										: isFreeAmountBonus && checkIsThereFreeAmountBonusError()
											? (
												<div className="vs--single-bet-stake-text vs--flex vs--flex-col vs--justify-center vs--text-right vs--single-bet-stake-error">
													<span className="vs--title-red vs--font-regular vs--font-small vs--pb-4">
														{t("bet.unallowedStack")}
													</span>
												</div>
											)
											: mode === BETSLIP_MODES.MULTI && isFactorsResultOverLimited(bets)
												? (
													<span className="vs--title-red vs--font-regular vs--font-small">
														{`${t("bet.maxFactorIs")} `}
														<span className="vs--title-red vs--font-regular vs--font-small vs--text-ltr">
															{isThereAtLeastOneKenoBet(bets)
																? MULTI_MODE_BETS_MAX_FACTORS.WITH_KENO_BET
																: MULTI_MODE_BETS_MAX_FACTORS.WITHOUT_KENO_BET}
														</span>
													</span>
												)
												: (
													<span className="vs--title-red vs--font-regular vs--font-small">
														{t("bet.maxPosWinAmountIs")}{" "}
														<span>
															{makeCurrencyText(maxPossibleWin, currency)}
														</span>
													</span>
												)
							}
						</div>
					)
					: null
			}
		</>
	)
};

export default StakeInput;