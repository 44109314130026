import ApiUrls from "constants/api.constants";

import httpService from "../http";

const betHistoryService = {
	history: (filters, signal) => {
		return httpService.get({
			path: ApiUrls.GET_HISTORY,
			queryData: { ...filters },
			signal
		});
	}
};

export default betHistoryService;
